<template>
    <modal ref="modalEntregasDias" titulo="Entregas en los Ultimos 90 Dias" tamano="modal-lg" no-aceptar>
        <div class="row mx-0 justify-center">
            <div class="col-auto px-1">
                <div class="br-16 border shadow px-4 py-2">
                    <p>
                        {{ agregarSeparadoresNumero(totales.pedidos) }} Pedidos
                    </p>
                    <p>
                        {{ agregarSeparadoresNumero(totales.entregas) }} Entregas
                    </p>
                    <p>
                        {{ separadorNumero(totales.valor) }}
                    </p>
                </div>
            </div>
            <div class="col-auto pr-1 d-middle">
                <img :src="tendero.avatar" width="54" height="54" class="obj-cover border rounded-circle" />
            </div>
            <div class="col-auto pt-4 px-0">
                <p class="text-gr-pink f-600 f-16">{{ tendero.nombre }}</p>
                <p class="f-14">Cedis: {{ tendero.cedis }} </p>
            </div>
        </div>
        <div class="d-flex mx-0 mt-3">
            <div class="col">
                <echart ref="graph" width="100%" :options="entregas_ultimos_dias" />
            </div>
        </div>
    </modal>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import Entregas from "../../../services/entregas/tabla";


Vue.component('echarts',echarts);

let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')

export default {
    data(){
        let self = this

        return{
            entregas_ultimos_dias: {
                backgroundColor: '#fff',
                legend: {
                    show: true ,
                    data: ['Valor', 'Pedidos']
                },
                tooltip: {
                    show: true
                },
                grid: {
                    top: '20%',
                    left: '5%',
                    right: '5%',
                    bottom: '15%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['dia', 'valor','pedidos'],
                    source: []
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function(params){
                        return `${params[0].value.fecha}: ${self.separadorNumero(params[0].value.valor)} </br>
                        ${params[0].value.pedidos} Pedidos`
                    }
                },
                series: [
                    {
                        name: 'Valor',
                        type: 'bar',
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral2},
                                    {offset: 0.2, color: colorGeneral2 },
                                ]
                            ),
                        },
                        barWidth: '10',
                        // data: [13, 40, 60, 50, 60, 40, 50, 60],
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol: 'circle',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 1, color: colorGeneral},
                                        {offset: 0.2, color: colorGeneral },
                                    ]
                                ),
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 1, color: colorGeneral},
                                        {offset: 0.2, color: colorGeneral },
                                    ]
                                ),
                            }
                        },
                        symbolSize:10,
                        // data: [13, 40, 60, 50, 60, 40, 50, 60],
                    },
                ],
            },
            tendero: {
                id: null,
                nombre: '',
                avatar: '',
                cedis: '',
            },
            totales: {
                pedidos: 0,
                entregas: 0,
                valor: 0
            }
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        })
    },
    methods: {
        toggle(tendero){
            setTimeout(() => {
                this.$refs.graph.handleResize()
            }, 200);

            this.tendero = tendero
            this.informe_dias()
            this.$refs.modalEntregasDias.toggle();
        },
        async informe_dias(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    tendero: this.tendero.id,
                }
                const { data } = await Entregas.informe_dias(params)
                this.$validar(data)

                this.totales = data.totales
                this.entregas_ultimos_dias.dataset.source = data.datos

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.br-16{ border-radius: 16px; }
</style>
